export const userToken = `t_token`;

export enum SettingSections {
  BASIC_INFO,
  BILLING,
  USERS_AND_TEAMS,
}
export enum EditOrderModalView {
  ENTER_INFO,
  PROCESSING,
  CONFIRM,
}
export enum ResendingTicketFlow {
  NONE,
  PROCESSING,
  CONFIRM,
}
export enum ManageOrderModalView {
  NONE,
  REFUND,
  UPDATE_INFO,
  SEND_SMS,
  OFFER,
  EDIT_ORDER,
  TRANSFER,
}
export enum TransferTicketModalView {
  NONE,
  ENTER_INFO,
  CONFIRM,
  PROCESSING,
  DONE,
}

export enum NewsletterModalView {
  ENTER_EMAIL,
  PROCESSING,
  CONFIRM_EMAIL,
}
export enum SmsModalView {
  ENTER_SMS,
  PROCESSING,
  CONFIRM_SMS,
  SENT_SMS,
  PREVIEW_SMS,
}
export enum PayoutModalView {
  PAYOUT_DETAILS,
  PROCESSING,
  CONFIRM_PAYOUT,
}
export enum PasswordModalView {
  ENTER_PASSWORD,
  PROCESSING,
}

export enum ScanTicketFacingMode {
  FRONT,
  BACK,
}

export enum ScanTicketMode {
  TIX_SCAN,
  LOOKUP,
  SHOW_TIX,
  VIEW_SCANNED,
}

export enum DashboardModalView {
  SEND_SMS,
}
export enum ManageWaitlistModalView {
  SEND_SMS,
}
export enum ManageEventModalView {
  REFUND,
  SEND_SMS,
  RESEND_TIX,
  PAYOUT,
}
export enum CreateFlow {
  BASIC_INFO,
  EVENT_IMAGE,
  TICKET_DETAILS,
  LINE_UP,
  COUPON_CODES,
  PHOTOS,
  CHECKOUT_FIELDS,
}

export enum CheckoutFlow {
  EVENT_VIEW,
  TICKET_SELECTION,
  USER_CHECKOUT,
  PAYMENT_CHECKOUT,
  PROCESSING,
  CONFIRMATION,
  ERROR,
}
